import React, { useState, useEffect, useRef } from "react";
import Card from './Card.tsx';
import { Profile } from "./Inspect.tsx";
import Field, { FieldType } from "./Field.tsx";
import Button, { ButtonType } from "./Button.tsx";
import ProfileList from "./ProfileList.tsx";
import { config } from "../config.ts";
import { text } from "../text.ts";
import ListPagination from "./ListPagination.tsx";

export async function loadPage(id) {

}

export default function Leaderboard({ endpoint = 'profiles' }) {

    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [page, setPage] = useState(-1);
    const [pageCount, setPageCount] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setWasError] = useState(false);
    const [submissions, setSubmissions] = useState(-1);

    const limit = 10;

    useEffect(() => {
        (async () => { await getPage(0);})();
        (async () => { await getSubmissions();})();
    }, []);

    async function changePage(newPage) {
        if (newPage < 0 || newPage >= pageCount) { return; }
        await getPage(newPage);
    }

    async function getPage(page = 0) {

        console.log(`fetch leaderboard`);
        setIsLoading(true); // Set loading state to true when the request starts

        const request = `${config.api}/${endpoint}?page=${page}`;
        console.log(`request: ${request}`);
        fetch(request)
            .then(response => {
                if (response.status === 500) {
                    return response.text().then(text => {
                        throw new Error(`leaderboard error: ${text}`);
                    });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setProfiles(data.results);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setWasError(true);
                setIsLoading(false);
            });

        setPage(page);

    }

    async function getSubmissions() {
        console.log('fetching discount');
        fetch(config.api + '/submissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log('discount ' + data);
                setSubmissions(data.message);
                setPageCount(Math.ceil(data.message / limit));
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <div className="w-full">
            <Card>
                <Field value={text.leaderboard} fieldType={FieldType.HEADER} />
                {/* {submissions > -1 && (
                    <Button buttonType={ButtonType.INACTIVE} passthrough={true}>{`${submissions} verdicts`}</Button>
                )} */}
                <div className="mt-[-1em] mb-[1em] w-[10em] m-auto">
                    <ListPagination isLoading={isLoading} page={page} pageCount={pageCount} onChange={changePage} />
                </div>
                <ProfileList
                    isLoading={isLoading}
                    profiles={profiles}
                    error={error}
                />
            </Card>
        </div>
    );
}

