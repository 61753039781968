const SFXType = {
    HOVER: 0,
    CLICK: 1,
    POPUP: 2,
    TYPEWRITER: 3,
    NUMBER: 4
};

const playSFX = (sfxPath) => {

    const playAudio = (audioPath) => {
        const audio = new Audio(audioPath);
        audio.play().catch(error => {

        });
    };

    if (sfxPath.includes('.')) {
        const directFileUrl = `/sfx/${sfxPath}`;
        playAudio(directFileUrl);
    } else {
        const sfxUrl = `/sfx/${sfxPath}/`;

        fetch(sfxUrl + 'index.json')
            .then(response => response.json())
            .then(sfxFiles => {
                if (sfxFiles.length > 0) {
                    const randomFile = sfxFiles[Math.floor(Math.random() * sfxFiles.length)];
                    const finalSfxPath = sfxUrl + randomFile;
                    playAudio(finalSfxPath);
                }
            })
            .catch(error => {
                console.error('Error fetching sound effects:', error);
            });
    }
};


module.exports = { playSFX };