
import React, { useState, useEffect, useRef } from 'react';
import Image from './Image.tsx';
import Field, { FieldType } from './Field.tsx';
import { config } from '../config.ts';
import Button, { ButtonType, Icon } from './Button.tsx';
import LoadingWheel from './LoadingWheel.tsx';
import ProgressBar from './ProgressBar.tsx';
import RowSimple from './RowSimple.tsx';
import CardMini from './CardMini.tsx';
import { PlusCircleIcon, MinusCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/24/solid';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid';
import Card from './CardMini.tsx';
import { playSFX } from '../utils/sfx.js';
import Confetti from 'react-confetti';


export interface Profile {
    id: number;
    score: number;
    rank: number;
    uri: string;
    response: string;
    message: string;
    json: Submission;
    warning: string;
    error: string;
}

export interface Submission {
    id: number;
    score: number;
    rank: number;
    name: string;
    virtues: string[];
    deductions: string[];
    uri: string;
    response: string;
    message: string;
    pondering: string;
}


export const DogInfoToSprite = (ft: DogInfo) => {
    if (ft === DogInfo.RANK) return `./img/IconRank.png`;
    if (ft === DogInfo.BREED) return `./img/IconBreed.png`;
    if (ft === DogInfo.SCORE) return `./img/IconPoints.png`;
    return '';
}

export enum DogInfo {
    NONE,
    BREED,
    SCORE,
    RANK,
}

export enum UnrollOrder {
    INSTANT_VERDICT,
    ANIMATE_VERDICT,
    BREED,
    VIRTUES,
    DEDUCTIONS,
    PONDERING,
    RANK,
    COMPLETE,
    HIDE,
}

const UnrollOrderLabels = {
    [UnrollOrder.INSTANT_VERDICT]: "Verdict",
    [UnrollOrder.ANIMATE_VERDICT]: "Verdict",
    [UnrollOrder.BREED]: "Species",
    [UnrollOrder.VIRTUES]: "Verdict",
    [UnrollOrder.DEDUCTIONS]: "Verdict",
    [UnrollOrder.PONDERING]: "Brainscan",
    [UnrollOrder.RANK]: "Rank",
    [UnrollOrder.COMPLETE]: "Finish",
    [UnrollOrder.HIDE]: "HIDDEN",
};

export default function Inspect({ profile, onClose, playAnimation = false }: { profile: Profile, onClose, playAnimation: boolean }) {

    const animate = !config.fastUI && playAnimation;

    const [animationStep, setAnimationStep] = useState(animate ? UnrollOrder.ANIMATE_VERDICT : UnrollOrder.COMPLETE);
    const [showBreed, setShowBreed] = useState(!animate);
    const [showRank, setShowRank] = useState(!animate);
    const [showVirtues, setShowVirtues] = useState(!animate);
    const [showDeductions, setShowDeductions] = useState(!animate);
    const [showPondering, setShowPondering] = useState(!animate);
    const [isLoading, setIsLoading] = useState(animate);

    const [displayedVirtues, setDisplayedVirtues] = useState<number[]>([]);
    const [displayedDeductions, setDisplayedDeductions] = useState<number[]>([]);

    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    // Update your components based on the current step
    useEffect(() => {

        // console.log(`Inspect: ${JSON.stringify(profile, null, 2)}`);

        if (!animate) { showAll(); return; };

        if(animationStep === UnrollOrder.ANIMATE_VERDICT) {
            playSFX('gavel');
        }

        console.log('width', ref.current.offsetWidth);
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);

        setShowBreed(animationStep >= UnrollOrder.BREED);
        setShowRank(animationStep >= UnrollOrder.RANK);
        setShowVirtues(animationStep >= UnrollOrder.VIRTUES);
        setShowDeductions(animationStep >= UnrollOrder.DEDUCTIONS);
        setShowPondering(animationStep >= UnrollOrder.PONDERING);
        setIsLoading(animationStep < UnrollOrder.COMPLETE);

    }, [animationStep, ref.current, profile.json.virtues, profile.json.deductions]);


    function showAll() {
        setDisplayedVirtues(profile.json.virtues.map((_, index) => index));
        setDisplayedDeductions(profile.json.deductions.map((_, index) => index));
        setAnimationStep(UnrollOrder.COMPLETE);
    }

    const advanceStep = () => {

        let newAnimationStep = animationStep;

        if (animationStep === UnrollOrder.INSTANT_VERDICT) {
            showAll();
            return;
        }

        if (animationStep == UnrollOrder.ANIMATE_VERDICT) {
            if (profile.json.virtues.length > 0) {
                setDisplayedVirtues([0]);
            }
            if (profile.json.deductions.length > 0) {
                setDisplayedDeductions([0]);
            }
        }

        if (animationStep === UnrollOrder.VIRTUES && displayedVirtues.length < profile.json.virtues.length) {
            // Increment displayedVirtues by one additional index
            setDisplayedVirtues(prev => [...prev, prev.length]);
        } else if (animationStep === UnrollOrder.DEDUCTIONS && displayedDeductions.length < profile.json.deductions.length) {
            // Increment displayedDeductions by one additional index
            setDisplayedDeductions(prev => [...prev, prev.length]);
        } else if (animationStep === UnrollOrder.RANK) {
            // Animation is complete, perform any final actions
            playSFX('gavel');
            onClose(); // or any other final action

        } else {
            setAnimationStep(prevStep => prevStep + 1);
            newAnimationStep = animationStep + 1;
        }

        if (newAnimationStep === UnrollOrder.VIRTUES || newAnimationStep === UnrollOrder.DEDUCTIONS) {
            playSFX(newAnimationStep === UnrollOrder.VIRTUES ? 'ooh' : 'boo');
        }
    };

    return (
        <>
            <div className='duration-200 h-min w-full grid grid-cols-1 gap-4 justify-center content-center'>

                <div ref={ref} className='relative duration-200 select-none w-auto min-h-min z-50 rounded-3xl'>

                    {animate ? (
                        <>
                            <Image imageURI={profile.uri} />
                            <div className='absolute top-0 left-0 bottom-0 right-0'>
                                <Confetti
                                    width={width}
                                    height={height*4}
                                />
                            </div>
                        </>
                    ) : (
                        <Button disabled={animate} onClick={onClose} passthrough={true}>
                            <Image imageURI={profile.uri} />
                        </Button>
                    )}

                </div>

                <div className='w-full'>
                    <CardMini>
                        {showBreed &&
                            <>
                                <Field value={profile.json.name} typeWriter={animate} fieldType={FieldType.HEADER} />
                            </>
                        }
                    </CardMini>
                </div>

                {showVirtues && (
                    <div className=''>
                        {displayedVirtues.map(index => (
                            <div key={index} className='flex flex-row-2 space-x-2'>
                                <PlusCircleIcon className='bg-bgCard rounded-full p-1 text-accept flex-none content-center align-middle m-auto w-[2em] h-[2em]' />
                                <div className='flex-wrap grow'><Field value={profile.json.virtues[index]} fieldType={FieldType.TEXTBOX} /></div>
                            </div>
                        ))}
                    </div>
                )}

                {showDeductions && (
                    <div className=''>
                        {displayedDeductions.map(index => (
                            <div key={index} className='flex flex-row-2 space-x-2'>
                                <MinusCircleIcon className='bg-bgCard rounded-full p-1 text-cancel flex-none content-center align-middle m-auto w-[2em] h-[2em]' />
                                <div className='flex-wrap grow'><Field value={profile.json.deductions[index]} fieldType={FieldType.TEXTBOX} /></div>
                            </div>
                        ))}
                    </div>
                )}

                {showPondering && (

                    <div className='w-full'>
                        <CardMini>
                            {/* <div className='flex flex-row gap-1 h-min p-0 w-full justify-center'> */}
                            <div className='w-[1.75em] m-auto text-textBlock mb-1'><ChatBubbleOvalLeftEllipsisIcon /></div>
                            <div className='text-justify align-middle m-auto w-[80%]'>
                                <Field
                                    className='font-serif text-[1em] leading-5 font-normal'
                                    value={`${profile.json.pondering}`}
                                    fieldType={FieldType.TEXTBOX} />
                            </div>
                            {/* {`"${profile.json.pondering}"`} */}
                            {/* </div> */}
                        </CardMini>
                    </div>

                )}

                {showRank && animate && (
                    <div className='w-[50%] animate-bounce m-auto'>
                        <CardMini>
                            <Field value={`#${profile.rank}!`} fieldType={FieldType.HEADER} />
                            {/* <div className='flex flex-row gap-1 h-min p-0 w-full justify-center'>
                        <Field value={`${profile.score}/100`} fieldType={FieldType.NUMBER} />
                        <div className='mx-2 w-full m-auto'><ProgressBar progress={profile.score} showText={false} height='h-2' /></div>
                    </div> */}
                        </CardMini>
                    </div>
                )}


            </div>

            {(animate && animationStep + 1 <= UnrollOrder.COMPLETE) && (

                <div className={`duration-200 my-2 m-auto flex-col space-y-2 ${animationStep+1 === UnrollOrder.COMPLETE ? 'w-[8em]' : 'animate-bounce'}` }>
            
                    <Button onClick={advanceStep} buttonType={ButtonType.ACCEPT}>

                        {animationStep === UnrollOrder.INSTANT_VERDICT || animationStep + 1 === UnrollOrder.COMPLETE ? (
                            // <div className='text-[1em]'><BookOpenIcon /></div>
                            <>{'Finish'}</>
                        ) : (
                            <>
                                <ArrowDownCircleIcon className='h-[2em] m-auto' />
                            </>
                        )}
                        
                    </Button>

                </div>
            )}
        </>
    );
}
