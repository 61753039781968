import React, { useEffect, useState } from "react";

import ImagePicker from './images/ImagePicker.tsx';
import Field, { FieldType } from "./Field.tsx";
import { Profile } from "./Inspect.tsx";
import { OrderProgress } from "./SubmitAndPay.tsx";
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import Button, { ButtonType } from './Button.tsx';

import { config } from '../config.ts';
import { text } from '../text.ts'
import OrderPresent from "./OrderPresent.tsx";

export default function OrderCreate({ orderProgress, handleOrderEvent, image, setImage, imageURI, setImageURI, openPayment, amount }) {

    const [discountOffer, setDiscountOffer] = useState(-1);

    useEffect(() => {

        console.log('fetching discount');
        fetch(config.api + '/submissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log('discount ' + data);
                setDiscountOffer(1000-data.message);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const setImageFile = (img: File | undefined) => {

        //do not let them change the image while the order is processing
        if (orderProgress > OrderProgress.CAN_ORDER) { return; }

        console.log("setting image");

        if (img === undefined) {
            setImage(undefined);
            setImageURI("");
            handleOrderEvent(OrderProgress.NONE);
        } else {

            console.log(`set Image: ${img.name}`);
            console.log(`set URI: ${URL.createObjectURL(img)}`);

            setImage(img);
            setImageURI(URL.createObjectURL(img));
            handleOrderEvent(OrderProgress.CAN_ORDER);
        }

        console.log("set image");

    };

    const canOrder = orderProgress === OrderProgress.CAN_ORDER && amount !== undefined;

    return (
        <>
            <Field value={text.submissionHeader} />

            <div className="relative">
                <ImagePicker image={image} imageURI={imageURI} setImage={setImageFile} />
            </div>

            {orderProgress === OrderProgress.NONE && (
                <div className="m-auto scale-90">
                    {/* <Field value={text.payDescription} fieldType={FieldType.TEXTBOX} /> */}
                    <Button disabled={true} buttonType={ButtonType.DEFAULT} passthrough={true}>
                        <>
                            {discountOffer !== -1 ?
                                (<>{`${text.payDiscount} (${discountOffer} left)`}</>) :
                                (<></>)}
                        </>
                    </Button>
                </div>
            )}

            {orderProgress === OrderProgress.CAN_ORDER && (
                <div className="w-[50%] m-auto mt-4">
                    <Button disabled={!canOrder} onClick={openPayment} buttonType={canOrder ? ButtonType.ACCEPT : ButtonType.INACTIVE}  >
                        <>{canOrder ? (`$${(amount / 100).toFixed(2)}`) : (``)}</>
                    </Button>
                </div>
            )}




        </>

    );
}
