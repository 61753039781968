
import React from 'react';

const RowSimple = ({ middle = <></>, left = <></>, right = <></> }) => {
    return (
        <>
            <div className="relative flex flex-row m-auto text-center h-[1.5em]">
                {left !== <></> && <div className="absolute w-[1.5em] ml-1">{left}</div>}
                {middle !== <></> && <div className="grow">{middle}</div>}
                {right !== <></> && <div className='absolute right-0 w-[1.5em] mr-1'>{right}</div>}
            </div>
        </>
    );
}

export default RowSimple;