import React, { useState, useEffect } from "react";
import CardMini from "./CardMini.tsx";
import Button from "./Button.tsx";
import LoadingWheel from "./LoadingWheel.tsx";


export enum LoginProgress {
  NONE,
}

export async function signUp(supabase, formEmail) {

  console.error("TODO: implement signUp")
  return formEmail;

  const { data, error } = await supabase.auth.signUp({
    email: `${formEmail}`,
    password: Math.random().toString(36).slice(-8),
    options: {//
      shouldCreateUser: true,
    },
  })

  if (error) {
    console.error(error);
    throw error;
  } else {
    //get returned user ID
    const userID = data.user.id;
    return userID;
  }
}


export async function signInWithOTP(supabase, email) {

  const { data, error } = await supabase.auth.signInWithOtp({
    email: email,
    options: {
      shouldCreateUser: true,
      emailRedirectTo: `${window.location.href}`,
    },
  });

  if (error) {
    console.error(error);
    throw error;
  } else {

  }
};

export const UserLoginOTP = ({ supabase }) => {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [enterCode, setEnterCode] = useState(false);
  const [message, setMessage] = useState('Send One-Time Password');

  const signIn = async () => {
    setIsLoading(true);
    setMessage('Processing');

    try {
      await signInWithOTP(supabase, email);
      setMessage('OTP sent');
      setEnterCode(true);
    } catch (error) {
      console.error('Error signing in:', error);
      setMessage('Error, try again in a minute');
      setEnterCode(false);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (otp) => {
    console.log("OTP submitted:", otp);
    setIsLoading(true);

    try {
      const { data: { session }, error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });

      if (error) {
        console.error('Error verifying OTP:', error);
        setMessage('OTP verification failed. Try again.');
      } else {
        setMessage('OTP verified. You are now logged in.');
        // Handle successful login here
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error during OTP verification.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
    const inputOtp = e.target.value;
    if (inputOtp.length === 6) {
      verifyOTP(inputOtp);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!enterCode) {
      signIn();
    }
  };

  return (
    <div className="flex flex-col space-y-2 bg-none bg-transparent px-4">
      {isLoading ? (
        <LoadingWheel />
      ) : (
        <form onSubmit={handleSubmit}>
          {!enterCode ? (
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="bg-bgColor border placeholder-textColor outline-none border-default text-textColor text-normal font-normal rounded-lg focus:ring-textColor focus:border-textColor block w-full px-2 py-1"
              required
            />
          ) : (
            <input
              type="text"
              value={otp}
              onChange={handleOTPChange}
              placeholder="Enter 6-digit code"
              maxLength={6}
              className="bg-bgColor font-mono font-light border placeholder-textColor outline-none border-default text-textColor text-center text-normal rounded-lg focus:ring-textColor focus:border-textColor block w-full p-2"
              required
            />
          )}
          {!enterCode && (
            <div className="mt-2 text-[.9em]">
              <Button disabled={email.length === 0} passthrough={true}>
                <>{message}</>
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};


{/* <Auth
        supabaseClient={supabase}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: 'black',
                brandAccent: 'black',
              },
            },
          },
        }}
        theme="dark"

      // providers={['google', 'facebook', 'twitter']}
      // showLinks={false}
      /> */}

