
import React from 'react';
import { twMerge } from 'tailwind-merge';

const Image = ({ imageURI, className='', background = false }) => {

    let imageClass = 'shadow-2xl shadow-textColor rounded-[inherit] select-none ';

    if (background) { imageClass += ' w-full h-full bg-center bg-cover bg-[image:var(--image-url)]'; }
    else { imageClass += ' object-cover'; }
    
    imageClass = twMerge(imageClass, className);

    return (
        <>
            {background === true ? (
                <div
                    className={imageClass}
                    style={{ '--image-url': `url(${imageURI})` }}
                />
            ) : (
                <img className={imageClass} src={imageURI} />
            )}
        </>
    );
}

{/* <div className="w-full h-full object-cover shadow-2xl shadow-textColor rounded-[2em]  text-white flex  bg-bgBehind bg-center bg-cover bg-[image:var(--image-url)]"
            style={{'--image-url': `url(${imageURI})` }} > */}

export default Image;