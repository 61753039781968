

const env = process.env.REACT_APP_ENV;
// console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
// console.log(`process.env: ${JSON.stringify(process.env,null,2)}` );

export const config = {
  
  debugGoodRequest: false,
  debugBadRequest: false, 

  fastUI: false,
  autoPay: false,

  //todo add endpoint like api/dogs/
  api: process.env.REACT_APP_SERVER,

  copyright: '© judge.fyi 2023',
  copyrightURL: 'https://judge.fyi/',
  feedbackURL: 'https://forms.gle/HT5ZwQbMdYqFL8ex9',

  headerImage: '/img/HeroImage.jpg',

};

console.log(`api: ${config.api}`);

export const sfx = {

}

