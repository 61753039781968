import React, { useEffect, useState } from "react";

export default function Card({ children }) {

    const [animate, setAnimate] = useState(true);
    useEffect(() => {
        let timeoutId = setTimeout(() => setAnimate(false), 50);
        return () => clearTimeout(timeoutId);
    }, []);

    let style = animate ? '0' : '100';

    return (

        <div
            className="m-auto justify-center gap-2 flex flex-col flex-shrink-0"
            style={{ opacity: style }}
        >
            {children}
        </div>
    );

}