import React, { useState, useEffect } from "react";

import ProfileList from "./ProfileList.tsx";
import { Profile } from "./Inspect.tsx";

import { config } from "../config.ts";


export default function UserSubmissions({user, session}) {

    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setWasError] = useState(false);

    useEffect(() => {

        const userEmail = user?.email;
        const page = 1;
        const limit = 10; // Set your desired limit
        const offset = (page - 1) * limit;

        console.log(`fetch profiles for ${userEmail}`);
        if(!session) { console.log(`no session`); return; }
        if(session.access_token == null) {
            console.log(`no session token`);
            return;
        }


        const fetchData = async () => {
            setIsLoading(true);

            try {
                const data = await fetchProfiles(limit, 0);
                setProfiles(data.results);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setWasError(true);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []); // Add dependencies if needed

    function fetchProfiles(limit, page) {

        const request = `${config.api}/api/submissions?page=${page}&limit=${limit}`;

        return new Promise((resolve, reject) => {
            console.log(`request: ${request}`);

            fetch(request, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            })
                .then(response => {
                    if (response.status === 500) {
                        return response.text().then(text => {
                            throw new Error(`Error fetching profiles: ${text}`);
                        });
                    } else {
                        return response.json();
                    }
                })
                .then(resolve)
                .catch(reject);
        });
    }

    return (
        <ProfileList
            isLoading={isLoading}
            profiles={profiles}
            error={error}
        />
    );
}

