import React, { useEffect, useState } from "react";
import Typewriter from 'typewriter-effect';
import Odometer from 'react-odometerjs';
import AnimateHeight from 'react-animate-height';
import { twMerge } from 'tailwind-merge'

export enum FieldAnimation {
    NONE,
    TYPEWRITER,
    COUNTUP,
}

export enum FieldType {
    CAPSULE,
    TEXTBOX,
    HEADER,
    NUMBER,
}

export default function Field({ value, className = '', fieldType = FieldType.CAPSULE, typeWriter = false }) {

    const [animate, setAnimate] = useState(true);
    const [height, setHeight] = useState(0);
    const isAnimating = typeWriter && value !== undefined;

    useEffect(() => {
        let timeoutId = setTimeout(() => setAnimate(false), 50);
        return () => clearTimeout(timeoutId);
    }, []);

    let fieldClass = `h-full transition-all duration-300 ${(!isAnimating || !animate ? '' : 'animate-pulse')} `;
    let style = '100'; //animate ? '0' : '100';

    if (fieldType === FieldType.CAPSULE) {
        fieldClass += `select-none font-header leading-8 text-textColor text-center font-black text-[1.5em] px-4`;
        // rounded-2xl bg-bgCard
    } else if (fieldType === FieldType.TEXTBOX) {
        fieldClass += ` bg-bgCard font-body text-left text-[.9em] text-textBlock rounded-xl p-1 font-normal leading-5`;
    } else if (fieldType === FieldType.HEADER) {
        fieldClass += `select-none font-title text-[3em] p-1 text-center text-textColor font-light`;
    } else if (fieldType === FieldType.NUMBER) {
        fieldClass += `select-none text-[1rem] px-2 text-center text-textButton font-black rounded-xl bg-default `;
    }

    fieldClass = twMerge(fieldClass, className);

    if (isAnimating) {
        if (isNaN(Number(value))) {
            const speed = value.length > 20 ? 1 : 10;
            return (
                <div
                    className={`${fieldClass}`}
                    style={{opacity:style}}
                >
                    {/* <AnimateHeight> */}
                    <Typewriter

                        options={{
                            strings: value,
                            delay: speed,
                            autoStart: true,
                            loop: false,
                            cursor: '',
                        }}

                        onInit={(typewriter) => {
                            typewriter.callFunction(() => {
                                // console.log('String typed out!');
                            })
                                .start();
                        }}
                    />
                    {/* </AnimateHeight> */}
                </div>
            );
        } else {
            return (
                <div
                    className={fieldClass}
                    style={{opacity:style}}
                >
                    {value}
                    {/* <Odometer value={Number(value)} duration={2000} format="(.ddd),dd" /> */}
                </div>
            );
        }
    } else {
        return (
            <div
                className={fieldClass}
                style={{opacity:style}}
            >
                {value}
            </div>
        );
    }


}