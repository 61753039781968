
import React from 'react';
import Field, { FieldType } from './Field.tsx';
import Button, { ButtonType } from './Button.tsx';
import Image from './Image.tsx';
import { config } from '../config.ts';
import { text } from '../text.ts';
import { useHover } from 'react-use';

const Header = ({ headerText }) => {

    // const element = (hovered) => ;
    // const [hoverable, hovered] = useHover(element);

    return (
        <div className='select-none'>
            <div className='w-[80%] rounded-3xl m-auto mb-2'>
                <Image imageURI={config.headerImage} />
            </div>
            <Field value={headerText} fieldType={FieldType.HEADER} />
            <div className='mt-[-1em] scale-[90%]'>
                <Button buttonType={ButtonType.INACTIVE} passthrough={true} disabled={true}>{text.subheading}</Button>
            </div>
        </div>
    )

}

export default Header;