import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import Button, { ButtonType } from "./Button.tsx";
import LoadingWheel from './LoadingWheel.tsx';
import { OrderProgress } from "./SubmitAndPay.tsx";
import { config } from "../config.ts";
import { text } from "../text.ts";

export default function CheckoutForm({ user, amount, orderProgress, onSubmit, onCancel }) {

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [formEmail, setFormEmail] = useState('');

  const authEmail = (event) => {
    setFormEmail(event.value.email);
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      console.log(`payment ${formEmail}`);
      await onSubmit(formEmail);
    } catch(error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const disable = isLoading || !stripe || !elements;

  return (

    <div className='grid grid-cols-1 gap-1 content-center justify-center'>

      <form id="payment-form" onSubmit={handleOnSubmit}>

        {!user && (
          <>
            <LinkAuthenticationElement id="link-authentication-element" onChange={authEmail} />
            <br />
          </>
        )}
        
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <br />
        <Button onClick={onSubmit} disabled={disable} id="submit" buttonType={ButtonType.ACCEPT}>
          {disable || orderProgress === OrderProgress.PAYING ? (
            <LoadingWheel />
          ) : (
            <>{text.payPrompt}</>
          )}
        </Button>

      </form>

      <Button onClick={onCancel} buttonType={ButtonType.INACTIVE} passthrough={true}>
        Cancel
      </Button>

    </div>

  );
}