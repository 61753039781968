import React, { useEffect, useState } from "react";
import { ListBulletIcon, TrophyIcon, ArrowRightOnRectangleIcon, XCircleIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid'
import Field, { FieldType } from "./Field.tsx";
import Card from './Card.tsx';
import Image from './Image.tsx';
import { UserLoginOTP } from "./UserLoginOTP.tsx";
import Button, { ButtonType } from "./Button.tsx";
import UserSubmissions from "./UserSubmissions.tsx";
import RowSimple from "./RowSimple.tsx";
import { config } from "../config.ts";
import { text } from "../text.ts";

export default function UserDashboard({ supabase, session, user }) {

  const [showSubmission, setShowSubmission] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  async function signOut() {
    const { error } = await supabase.auth.signOut()
  }

  function toggleSubmissions() {
    setShowSubmission(!showSubmission);
  }

  //use this backend as an effect
  //app.get('/api/submissionCount', async (req, res) 
  useEffect(() => {

    if (user != null) {

      console.log(`fetching getProfile`);
      fetch(config.api + '/api/getProfile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          console.log(JSON.stringify(data, null, 2));
          setUserProfile(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });

    } else {
      console.log('no user');
    }

  }, [user]);


  function goToSubmissions() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (

    <div className="w-full">

      <Card>

        {!user || !userProfile ? (
          <>
            <Field value={'Sign In'} fieldType={FieldType.HEADER} />
            <UserLoginOTP supabase={supabase} />
          </>
        ) : (
          <>
            <Field value={'Profile'} fieldType={FieldType.HEADER} />
            <div className="w-full grid grid-cols-1 gap-1">

              <Button buttonType={ButtonType.DEFAULT} onClick={toggleSubmissions}>
                <RowSimple
                  middle={<>{text.submissions}</>}
                  left={<Image imageURI="./img/IconBreed.png" />}
                  right={<>{!userProfile ? '?' : userProfile.submissions}</>}
                />
              </Button>
              <div className='flex flex-row w-full space-x-2'>

              </div>

              {showSubmission && (
                <div className="py-4">
                  <UserSubmissions user={user} session={session} />
                </div>
              )}

              <div className='flex flex-row w-full justify-center space-x-2'>
                <div className="w-[5em]">
                  <Button buttonType={ButtonType.DEFAULT} onClick={goToSubmissions}>
                    <RowSimple
                      right={<>{`${!userProfile ? '?' : userProfile.tokens}`}</>}
                      left={<CurrencyDollarIcon />}
                    />
                  </Button>
                </div>
                <div className="grow">
                  <Button buttonType={ButtonType.INACTIVE} disabled={true}><div className="text-center">{`${user?.email}`}</div></Button>
                </div>

                <div>
                  <Button buttonType={ButtonType.INACTIVE} onClick={signOut}><ArrowRightOnRectangleIcon className="m-auto h-[1.5em]" /></Button>
                </div>

              </div>
            </div>
          </>
        )}
      </Card>




    </div>
  );

}