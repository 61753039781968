import React, { useEffect, useState } from 'react';

import Header from './components/Header.tsx';
import UserDashboard from './components/UserDashboard.tsx';
import SubmitAndPay from './components/SubmitAndPay.tsx';
import Leaderboard from './components/Leaderboard.tsx';
import { Session, User } from '@supabase/supabase-js';
import { createClient } from '@supabase/supabase-js'

import { config } from './config.ts';
import { text } from './text.ts';
import { changeTheme } from "./utils/theme.ts";
import Button from './components/Button.tsx';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANONPUBLIC);
console.log(`DEV_MODE: ${process.env.DEV_MODE}`);
console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);
console.log(`SERVER: ${process.env.REACT_APP_SERVER}`);
console.log(`supabase: ${process.env.REACT_APP_SUPABASE_URL}`);

export default function Entry({ }) {

  changeTheme("theme3");

  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      
      console.log(`session`);
      setSession(session);
      setUser(session ? session.user : null);

      if(!session) {console.log('NO SESSION'); return;}
      else {
        fetch(config.api + '/api/protectedHello', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
        })
        .then(response => response.json()) // First, convert the response to JSON.
        .then(data => {
          console.log('protected hello ' + data.message); // Then access the message key in the JSON.
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }

    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session ? session.user : null);
    })

    return () => subscription.unsubscribe()
  }, [])
  
  return (
    <>
      <div className='z-5 min-w-min text-textColor font-normal font-header'>
        <div className='max-w-md bg-bgColor m-auto p-10'>
        {/* shadow-l */}
          <div className='max-w-md m-auto justify-center flex flex-col gap-12'>
            <Header headerText={text.header} />
            {/* <Button>{process.env.NODE_ENV}</Button> */}
            <SubmitAndPay supabase={supabase} session={session} user={user} />
            <Leaderboard />
            <UserDashboard supabase={supabase} user={user} session={session} />
          </div>
          <div className='flex flex-row space-x-4 mt-4 mb-[-2em] content-center justify-center m-auto text-center text-textColor text-opacity-80 text-xs'>
            <a className='hover:underline' href={config.feedbackURL} target='_blank'>{'Feedback'}</a>
            <a className='hover:underline' href={config.copyrightURL} target='_blank'>{config.copyright}</a>
          </div>
        </div>
      </div>
    </>

  );

}

