import React, { useEffect, useState } from "react";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import Button, { ButtonType } from "./Button.tsx";

export default function ListPagination({ isLoading, onChange, page = -1, pageCount = -1 }) {

    const canUseButtons = !isLoading && page !== -1;
    const hasNextPage = canUseButtons && page < pageCount-1;
    const hasPreviousPage = canUseButtons && page >= 1;

    function onBack() {
        if (hasPreviousPage) {
            onChange(page - 1);
        }
    }

    function onForward() {
        if (hasNextPage) {
            onChange(page + 1);
        }
    }

    function changePage(e) {
        onChange(Number(e.target.value)-1);
    }

    return (
        <>
            {page >= 0 && (
                <div className="flex flex-row justify-center space-x-4 w-auto m-auto align-middle">
                    <div className="flex align-middle object-center m-auto">
                        <Button
                            disabled={!hasPreviousPage}
                            buttonType={hasPreviousPage ? ButtonType.DEFAULT : ButtonType.INACTIVE}
                            passthrough={true}
                            onClick={onBack}>
                            <ArrowLeftCircleIcon className='m-auto h-[2em]' />
                        </Button>
                    </div>

                    <div className="flex grow items-center w-full">
                        <div className='flex flex-nowrap w-full gap-2 select-none'>
                            <input type='number' value={page+1} min={1} max={100} onChange={changePage} onSubmit={changePage} className='flex-none text-center bg-transparent rounded-xl border-[1px] border-textColor' style={{ width: 'auto' }} />
                            <div className='flex-grow text-center whitespace-nowrap'>{` of ${pageCount}`}</div>
                        </div>
                    </div>

                    <div className="flex align-middle object-center m-auto">
                        <Button
                            disabled={!hasNextPage}
                            buttonType={hasNextPage ? ButtonType.DEFAULT : ButtonType.INACTIVE}
                            passthrough={true}
                            onClick={onForward}>
                            <ArrowRightCircleIcon className='m-auto h-[2em]' />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );

}