import React, { useState } from "react";
import Image from "./Image.tsx";
import Button from "./Button.tsx";
import { Profile } from "./Inspect.tsx";
import ProgressBar from "./ProgressBar.tsx";
import Field, { FieldType } from "./Field.tsx";

export default function ProfileCard({ isLoading, profile, openProfile }: { isLoading: boolean, profile: Profile, openProfile }) {

    const [hovering, setHovering] = useState(false);

    // Conditionally add animate-pulse class
    const cardClass = `duration-100 w-full rounded-3xl py-1 px-12 ${isLoading ? 'animate-pulse' : ''}`;
    let imageClass = `duration-100 select-none rounded-3xl w-auto object-cover h-[8em] w-[8em]`; //object-top

    // if (hovering) {
    //     imageClass += ' flex grow min-h-max max-h-[16em] my-1';
    // } else {
    //     imageClass += ' h-[8em] w-[8em]';
    // }

    function onHover() {
        setHovering(true);
    }

    function onExit() {
        setHovering(false);
    }

    return (

        <Button onHover={onHover} onExit={onExit} onClick={() => { openProfile(profile) }} passthrough={true}>
            <div className={cardClass}>

                {isLoading === false && (

                    <div className="duration-200 w-full text-left flex space-x-4">

                        <Image className={imageClass} imageURI={profile.uri} />

                        {!hovering && (
                            <div className="relative flex-col-1 space-y-2 pr-2 m-auto">
                                <div className="flex flex-row space-x-2 m-auto align-middle content-center ">
                                    <Field value={`#${profile.rank}`} fieldType={FieldType.NUMBER} />
                                    {/* <div className="w-full h-full m-auto"><ProgressBar progress={profile.score} total={100} showText={false} /></div> */}
                                    {/* <div className="m-auto text-textBlock text-[1em] tracking-tighter">{profile.score}</div>  */}
                                </div>
                                <div className="text-normal font-bold leading-5 break-words max-w-[8em]">{profile.json.name}</div>
                            </div>
                        )}

                    </div>
                )}
            </div>
        </Button>
        
    );


}
