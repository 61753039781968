import React from "react";

export default function CardMini({ children }) {

    return (
        <div className="transition-all duration-500 select-none leading-8 bg-bgCard text-center font-black rounded-2xl p-1">
            {children}
        </div>
    );

}