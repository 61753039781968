export const text = {

    header: "Dog Judge",
    subheading: 'Find out how cute is your dog really is.',
    description: "Your pup versus the world.",

    submissionHeader: "Submit Dog",
    
    payAction: "Add your dog to the pup leaderboard!",
    freeDiscount: "FREE for early pups",
    payDiscount: "$1 off early pups",
    payDescription: "Your dog will be found guilty of many cuteness crimes, recieve a score, and be given global rank.",
    payPrompt: "Process Puppy",
    //    payPrompt: "Add 3 Puppy Points",

    payProcessing: "Deliberating",
    paySuccess: "Verdict",
    payError: "Case rejected",

    submissions: "Your Pups",

    leaderboard: "Best Pups",
    acceptVerdict: "Add to Leaderboard",
}