import React, { useState, useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Card from "./Card.tsx";
import OrderCreate from "./OrderCreate.tsx";
import OrderPresent from "./OrderPresent.tsx";
import OrderProcess from "./OrderProcess.tsx";

import { sendImageToServer } from './ImageProcessing.ts';
import { config } from '../config.ts';
import { text } from '../text.ts'
import { playSFX } from "../utils/sfx.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export enum OrderProgress {
    NONE,
    CAN_ORDER,
    PRESENTED,
    PROCESSING,
    CANCEL,
    ERROR,
    SUCCESS,
    SEND_ORDER,
    PRESENT_ORDER,
}

export default function SubmitAndPay({ supabase, session, user }) {

    const [orderProgress, setOrderProgress] = useState(OrderProgress.NONE);
    const [result, setResult] = useState();
    const [image, setImage] = useState<File>();
    const [imageURI, setImageURI] = useState("");

    const [clientSecret, setClientSecret] = useState("");
    const [amount, setAmount] = useState();

    const appearance = {
        theme: 'light',

        // variables: {
        //     colorPrimary: '#0570de',
        //     colorBackground: '#eeeeee',
        //     colorText: '#888888',
        //     colorDanger: '#df1b41',
        //     fontFamily: 'Ideal Sans, system-ui, sans-serif',
        //     spacingUnit: '3px',
        //     borderRadius: '10px',
        //     // See all possible variables below
        // }
    };

    const loader = 'auto';

    const options = {
        clientSecret,
        appearance,
        loader,
    };

    const createPaymentIntent = async (user) => {
        
        try {
            const response = await fetch(`${config.api}/create-payment-intent`, {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify(
                    {
                        userID: user?.id,
                        userEmail: user?.email,
                        //items: [{ id: "xl-tshirt" }]
                    },
                ),
            });

            const data = await response.json();


            console.log('PaymentIntent');
            // console.log(JSON.stringify(data, null, 2));
            console.log(`amount ${data.clientSecret.amount}`);
            console.log(`secret ${data.clientSecret.client_secret}`);

            setAmount(data.clientSecret.amount);
            setClientSecret(data.clientSecret.client_secret);

        } catch (error) {
            console.error('Uh OH:', error);
        }
    };

    const presentPayment = async () => {
        try {

            setOrderProgress(OrderProgress.PRESENTED);
            if (config.autoPay) {
                onSuccess('neilson.ks@gmail.com');
            }
        } catch (error) { }
    }

    const sendOrder = async (email) => {

        console.log('ordering');
        setOrderProgress(OrderProgress.SEND_ORDER);

        if (config.autoPay) { await new Promise(resolve => setTimeout(resolve, 1000)); }

        try {
            const response = await sendImageToServer(email, session, image);
            console.log(`recieved ${JSON.stringify(response)}`);

            setResult(response);
            setOrderProgress(OrderProgress.PRESENT_ORDER);
            setImage(undefined);
            setImageURI('');

        } catch (e) {
            console.error('Error sending order:', e);
            setOrderProgress(OrderProgress.CAN_ORDER);
        } finally {

        }
    }

    //gross
    const onSubmit = () => { handleOrderEvent(OrderProgress.PROCESSING); }
    const onCancel = () => { handleOrderEvent(OrderProgress.CANCEL); }
    const onSuccess = (email) => { 
        handleOrderEvent(OrderProgress.SUCCESS); 
        sendOrder(email);
    }
    const onError = () => { handleOrderEvent(OrderProgress.ERROR); }
    const onExit = () => { handleOrderEvent(OrderProgress.NONE); }

    async function handleOrderEvent(event: OrderProgress) {

        setOrderProgress(event);

        try {

            if (event === OrderProgress.NONE) {

            } else if (event === OrderProgress.CAN_ORDER) {
                if (clientSecret === "") {
                    await createPaymentIntent(user);
                }
            } else if (event === OrderProgress.CANCEL) {
                setOrderProgress(OrderProgress.CAN_ORDER);
            } else {

            }
        } catch (error) {
            console.error('Error:', error);
        }

    }


    return (
        <div className="w-full">
            <Card>

                {orderProgress < OrderProgress.SEND_ORDER && (
                    <OrderCreate
                        orderProgress={orderProgress}
                        handleOrderEvent={handleOrderEvent}
                        image={image}
                        setImage={setImage}
                        imageURI={imageURI}
                        setImageURI={setImageURI}
                        openPayment={presentPayment}
                        amount={amount}
                    />
                )}

                {orderProgress < OrderProgress.SEND_ORDER && clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        {orderProgress > OrderProgress.CAN_ORDER && orderProgress < OrderProgress.SUCCESS && (
                            <OrderProcess
                                supabase={supabase}
                                session={session}
                                user={user}
                                orderProgress={orderProgress}
                                amount={amount}
                                onSubmit={onSubmit}
                                onSuccess={onSuccess}
                                onError={onError}
                                onCancel={onCancel}
                            />
                        )}
                    </Elements>
                )}
            </Card>

            {
                (orderProgress >= OrderProgress.SUCCESS) && (
                    <OrderPresent
                        orderProgress={orderProgress}
                        result={result}
                        onExit={onExit}
                    />
                )
            }

        </div >

    );
}

