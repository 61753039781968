
import React from 'react';

export default function Fullscreen({ children }) {

    return (
        <>
            {/* <div className='z-10 fixed left-0 top-0 w-screen h-screen bg-bgBehind bg-opacity-50'>
            </div> */}
            <div className='w-full z-20 top-[5em] grid-cols-1 max-w-md mx-auto justify-center content-center'>
                {children}
            </div>
        </>
    );
}

