import React, { useState, useEffect } from 'react';
import Entry from './Entry.tsx'

export default function App() {

  return (
    <Entry />
  );

}

