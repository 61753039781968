import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUpIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/solid'
import { playSFX } from '../../utils/sfx.js';
import Image from '../Image.tsx';
import { config } from '../../config.ts';



export enum PickerState {
  NONE,
  SELECTED,
  ERROR,
}

const uriToFile = async (uri: string): Promise<File | undefined> => {
  try {
    const response = await fetch(uri);
    const blob = await response.blob();
    return new File([blob], "filename", { type: blob.type });
  } catch (error) {
    console.error('Error converting URI to file:', error);
  }
};

export const ImagePicker = ({ image, imageURI, setImage, disabled = false }) => {

  const [pickerState, setPickerState] = useState<PickerState>(PickerState.NONE);
  const dummy = "https://engine.study/branch/groen_small.png";

  const handleFileChange = async (newFiles: File[]) => {
    handlePhotoChange(newFiles[0]);
  }

  const handlePhotoChange = async (event) => {

    var img;

    if (config.debugGoodRequest) {
      console.log("debug " + dummy);
      img = await uriToFile(dummy);
    } else if (event.target.files && event.target.files.length > 0) {
      img = event.target.files[0];
    } else {
      return;
    }

    if (img === undefined) { setPickerState(PickerState.NONE); }
    else { setPickerState(PickerState.SELECTED); }

    console.log(img?.name);
    setImage(img);

    if (img !== undefined) {
      playSFX('happybark');
    }

  };


  interface BackendResponse {
    message: string;
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': []
    },
    multiple: false,
  });

  // <Dropzone multiple={false} onDrop={acceptedFiles => console.log(acceptedFiles)}>

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  // console.log(`render image: ${imageURI}`);

  return (

    <div className="w-full m-auto grid grid-cols-1 gap-5 content-center items-center justify-center text-center">

      <div {...getRootProps({ className: 'dropzone' })}>
        <input disabled={disabled} {...getInputProps()} onChange={handlePhotoChange} />
        <label htmlFor="dropzone-file" className="w-full flex flex-col items-center justify-center cursor-pointer bg-gradient-to-t from-bgCard md:from-bgCard">

          <div
            className="w-full duration-200 items-center justify-center text-textColor flex border-2 border-textColor border-dashed "
            style={{ borderRadius: imageURI ? '4em' : '20em', height: imageURI ? '20em' : '4em' }}
          >
            {imageURI === "" ? (
              <>
                {!disabled && (
                  <div className="animate-bounce z-0 w-8 h-8 mt-2" ><CloudArrowUpIcon /></div>
                )}
                {/* <p className="text-xs ">PNG, JPG or GIF</p> */}
              </>
            ) : (
              <div className='w-full h-full p-2 rounded-[inherit]'>
                <Image imageURI={imageURI} background={true} />
              </div>
            )}

          </div>
        </label>


      </div>
      {/* 
        <aside>
          <h4>Accepted files</h4>
          <ul>{acceptedFileItems}</ul>
        </aside>
        <aside>
          <h4>State</h4>
          <ul>{PickerState[pickerState]}</ul>
        </aside> */}


    </div>

  );

}

export default ImagePicker;





