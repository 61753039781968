// OrderPresent.tsx
import React from 'react';
import Fullscreen from "./Fullscreen.tsx";
import Card from "./Card.tsx";
import LoadingWheel from "./LoadingWheel.tsx";
import Inspect from "./Inspect.tsx";
import Button, { ButtonType } from './Button.tsx';
import { OrderProgress } from './SubmitAndPay.tsx';
import { Profile } from "./Inspect.tsx";
import { text } from '../text.ts';
import Field, { FieldType } from './Field.tsx';

const OrderPresent = ({ orderProgress, result, onExit }) => {

    const profileData: Profile = result as Profile;

    if (orderProgress === OrderProgress.PRESENT_ORDER) {
        //todo handle profile error
        if (profileData === undefined) {
            console.error('Bad response');
            console.log(result);
            return;
        }

        console.log('Score: ' + profileData.score);

    }

    return (
        <Fullscreen>
            <Card>
                <Field value={'Verdict'} />
                {orderProgress < OrderProgress.PRESENT_ORDER || profileData === undefined ? (
                    <LoadingWheel />
                ) : (
                    <>
                        {profileData.error ? (
                            <>
                                <Button onClick={onExit} buttonType={ButtonType.DECLINE}>
                                    {'Error!'}
                                </Button>
                                <Field className='px-10'
                                    value={`${profileData.message} Please try again, your order was refunded!`}
                                    fieldType={FieldType.TEXTBOX}
                                />

                            </>
                        ) : (
                            <Inspect profile={profileData} onClose={onExit} playAnimation={true} />
                        )}
                    </>

                )}
            </Card>
        </Fullscreen>
    );
};

export default OrderPresent;
