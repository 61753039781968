import React, { useState, useEffect } from "react";
import { useElements, useStripe } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm.jsx';
import Button from "./Button.tsx";
import { UserLoginOTP, signInWithOTP, signUp } from './UserLoginOTP.tsx';
import { OrderProgress } from "./SubmitAndPay.tsx";
import { config } from '../config.ts';


export default function OrderProcess({ supabase, session, user, orderProgress, amount, onSubmit, onSuccess, onError, onCancel }) {

    const stripe = useStripe();
    const elements = useElements();
    const host = window.location.origin;

    const [message, setMessage] = useState('');

    async function handleOnSubmit(formEmail) {

        if(formEmail === undefined) {
            console.error('formEmail is undefined');
            return;
        }

        try {

            //make sure we have a supabase userID
            const userID = await confirmAccount(user, formEmail);

            //send the payment to stripe with the userID
            await confirmPayment(userID, formEmail);

            //now send the order
            setMessage('');
            
            onSubmit();
            onSuccess();

        } catch (error) {
            setMessage(error.message);
            onError();
        } finally {
        }
    

    }

    async function confirmAccount(currentUser, formEmail) {

        let userID = null;

        try {
            if (!currentUser) {
                console.log(`submitting email ${formEmail}`);
                userID = await signUp(supabase, formEmail);
            } else {
                console.log(`submitting currentUser ${currentUser.email}`);
                userID = currentUser.id;
            }

            console.log(`userID: ${userID}`);

            //return the userID
            if (!userID) {
                throw new Error('userID is null');
            } else {
                return userID;
            }

        } catch (error) {
            throw error;
        }

    }

    async function getTokens() {
        //app.get('/api/getRemainingOrders'
        const response = await fetch(`config.api` + '/api/getRemainingOrders', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
            },
        });

        const json = await response.json();
        console.log(JSON.stringify(json, null, 2));
        return json.tokens;

    }

    async function confirmPayment(userID, formEmail) {

        if (!stripe || !elements) {
            console.log("Stripe.js hasn't loaded yet.");
            onError();
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: `${host}`,
                payment_method_data: {
                    // Add any additional data here
                    billing_details: {
                        email: formEmail,
                    },
                    metadata: {
                        user_id: userID,
                        user_email: formEmail,
                    },
                },
            },
        });

        if (error) {
            console.log(error.message);
            throw error;
        }
    }

    // useEffect(() => {
    //     getTokens().then((tokens) => {
    //         console.log(`tokens: ${tokens}`);
    //     }).catch((error) => {
    //         console.error(error);
    //     });
    // });

    return (
        <>
            <div className="max-w-xs m-auto">
                <br />
                <CheckoutForm
                    user={user} amount={amount}
                    orderProgress={orderProgress}
                    onSubmit={handleOnSubmit}
                    onCancel={onCancel}
                />

                {message &&
                    <>
                        <br />
                        <Button disabled={true}>
                            <div id="payment-message">{message}</div>
                        </Button>
                    </>
                }
            </div>
        </>
    );
}


