import React from "react";
import { playSFX } from "../utils/sfx";

export enum ButtonType {
    DEFAULT,
    ACCEPT,
    DECLINE,
    INACTIVE,
}

export default function Button({ children, onClick = () => {}, onHover = () => {}, onExit = () => {}, disabled = false, buttonType = ButtonType.DEFAULT, icon = <></>, passthrough=false, bgOverride=''}) {

    const sizing = `w-full select-none m-auto justify-center content-center text-center z-0 hover:z-20`;

    let bg = '';
    let text = 'font-bold ';
    let color = '';

    if(passthrough) {

        if(disabled || buttonType === ButtonType.INACTIVE) {
            text += 'text-textDisabled';
            bg += 'opacity-50';
        } else {
            bg += 'hover:opacity-100 ';
        }
        
    } else if (disabled || buttonType === ButtonType.INACTIVE) {
        bg += 'bg-disabled bg-opacity-50 border-none opacity-50 ';
        text += 'text-textButton font-bold ';
    } else if (buttonType === ButtonType.DEFAULT) {
        bg += 'bg-default border-none shadow-l shadow-default hover:shadow-xl hover:shadow-default ';
        text += 'text-textButton font-bold hover:underline ';
    } else if (buttonType === ButtonType.ACCEPT) {
        bg += 'bg-accept border-none shadow-l shadow-accept hover:shadow-xl hover:shadow-accept ';
        text += 'text-textButton font-bold hover:underline ';
    } else if (buttonType === ButtonType.DECLINE) {
        bg += 'bg-cancel border-none shadow-l shadow-cancel hover:shadow-xl hover:shadow-cancel ';
        text += 'text-textButton font-bold hover:underline ';
    }

    if(bgOverride) {
        color = ' ' + 'bg-' + bgOverride;
    }

    const border = passthrough ? ' rounded-[inherit]' : ' rounded-full border-2 p-2'
    const styling = `w-full relative ${border} ${bg} ${text} m-auto justify-center content-center text-center`;

    const final = styling + ' ' + sizing;

    const hovered = () => {
        if(disabled) return;
        // playSFX('hover');
        onHover();
    }

    const exited = () => {
        if(disabled) return;
        onExit();
    }

    const clicked = () => {
        if(disabled) return;
        onClick();
        // playSFX(ButtonType[buttonType].toLowerCase());
    }
    

    return (
            <button className={final} disabled={disabled} onClick={clicked} onPointerOver={hovered} onPointerOut={exited}>
                {icon !== <></> && <Icon icon={icon} />}
                {children}
            </button>
    );

}

export function Icon({ icon = <></>, color ='text-textButton' }) {
    const className = `${color} absolute ml-1 l-0 w-[1.5em]`
    return (
        <>
        <div className={className}>
                {icon}
        </div>
        </>
    );
}
