// Leaderboard.tsx
import React, { useState, useEffect, useRef } from "react";
import Card from './Card.tsx';
import ProfileCard from "./ProfileCard.tsx";
import Inspect, { Profile } from "./Inspect.tsx";
import Field, { FieldType } from "./Field.tsx";
import Button, { ButtonType } from "./Button.tsx";

import { config } from "../config.ts";

export default function ProfileList({ isLoading, profiles, error }) {

    const [selection, setSelection] = useState<Profile>();
    const profileRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

    useEffect(() => {
        if (!isLoading && selection && profileRefs.current[selection.id]) {
            const element = profileRefs.current[selection.id].current;

            if (element) {
                console.log(`scroll to ${selection.id}`);
                const elementRect = element.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middle = absoluteElementTop - (window.innerHeight / 4) + (elementRect.height / 2);

                window.scrollTo({
                    top: absoluteElementTop,
                    behavior: 'smooth'
                });
            }
        }
    }, [selection]);


    function openProfile(selected: Profile) {
        if (!profileRefs.current[selected.id]) {
            profileRefs.current[selected.id] = React.createRef();
        }
        setSelection(selected);
    }

    function closeProfile() {
        setSelection(undefined);
    }

    const noProfiles = !isLoading && (profiles == null || profiles?.length === 0);

    return (
        <div className='duration-100 w-full justify-center content-center flex flex-col snap-y snap-mandatory'>
    
            {(error || (noProfiles)) && (
                <Button buttonType={ButtonType.INACTIVE} passthrough={true} disabled={true}>{'No Submissions'}</Button>
            )}
    
            {!noProfiles && profiles.map(profile => {
                let ref = profileRefs.current[profile.id] || React.createRef<HTMLDivElement>();
                profileRefs.current[profile.id] = ref;
    
                return (
                    <div ref={ref} key={profile.id} className='duration-200 items-center justify-center flex-shrink-0 flex snap-always snap-center'>
                        {selection && selection === profile ? (
                            <div className="my-4">
                                <Inspect profile={selection} onClose={closeProfile} playAnimation={false} />
                            </div>
                        ) : (
                            <>
                                <ProfileCard
                                    isLoading={false}
                                    profile={profile}
                                    openProfile={() => openProfile(profile)}
                                />
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
    
}

//vertical scroll
{/* <div className='w-full h-full snap-y snap-mandatory overflow-scroll bg-gray-500'>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-center bg-green-500">
    <p className="text-[2em] select-none">1</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-center bg-blue-500">
    <p className="text-[2em] select-none">2</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-center bg-teal-500">
    <p className="text-[2em] select-none">3</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-center bg-yellow-500">
    <p className="text-[2em] select-none">4</p>
</div>
</div> */}

//horizontal scroll
{/* <div className='w-full h-full grid-rows-1 snap-x flex snap-mandatory overflow-x-scroll bg-gray-500'>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-start bg-green-500">
    <p className="text-[2em] select-none">1</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-start bg-blue-500">
    <p className="text-[2em] select-none">2</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-start bg-teal-500">
    <p className="text-[2em] select-none">3</p>
</div>
<div className="w-full h-full flex-shrink-0 flex items-center justify-center snap-always snap-start bg-yellow-500">
    <p className="text-[2em] select-none">4</p>
</div>
</div> */}