
import { config } from "../config.ts";
import { Profile } from "./Inspect.tsx";

export const sendImageToServer = async (email, session, image) => {

    console.log('sendImageToServer');

    if (image === undefined) { console.error("no image"); return; }

    console.log(`sending ${image.name}`);
    let responseData;

    try {
        let imageData = await convertFileToBase64(image);

        console.log('Sending to server');

        const serverResponse = await fetch(config.api + '/uploadImage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session?.access_token}`
            },
            body: JSON.stringify(
                {
                    email: email,
                    image: imageData,
                    debug: {
                        skip: config.debugGoodRequest,
                        badRequest: config.debugBadRequest
                    }
                }),
        });

        responseData = await serverResponse.json();

        if (!serverResponse.ok) {
            throw new Error('Network response was not ok');
        } else {
            console.log('Got response from server');
        }

        return responseData;

    } catch (error) {
        console.log(`Error: ${error}`);
        throw new Error(error);
    } finally {
        return responseData;
    }
};

function convertFileToBase64(img: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            // The result attribute contains the data as a base64 encoded string
            resolve(fileReader.result as string);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };

        fileReader.readAsDataURL(img);
    });
}